import axios from "axios";
import {
  setFilteredProducts,
  setFilterExpanded,
  setLoading,
  setNoProductsFound,
} from "../store/reducers/filterSlice";
import { SERVER_URL } from "../helpers/app.constants";

export const handleFilterSubmit = async (
  values,
  dispatch,
  heelHeights,
  sizes,
  dynamicTags,
  dynamicColors,
  isSmallScreen,
  navigate,
  setFiltersExpanded
) => {
  const filtersPayload = {
    color: values.filters.filter((f) => dynamicColors.includes(f)),
    tags: values.filters.filter((f) => dynamicTags.includes(f)),
    heelHeight: values.filters.filter((f) => heelHeights.includes(f)),
    size: values.filters.filter((f) => sizes.includes(f)),
    minPrice: values.minPriceFormik || 0,
    maxPrice: values.maxPriceFormik || 50000,
    name: values.searchQuery || "",
  };

  // console.log("Updated filters:", values.filters); // Log the filters
  // console.log("Filters Payload to be sent:", filtersPayload); // Log the final filters payload

  const validFilters = Object.keys(filtersPayload).filter(
    (key) => filtersPayload[key].length > 0 || key === 'minPrice' || key === 'maxPrice'
  );

  if (validFilters.length === 0) {
    console.log("No valid filters selected.");
    dispatch(setNoProductsFound(true));
    return;
  }

  try {
    dispatch(setLoading(true));

    const response = await axios.post(
      `${SERVER_URL}product/filterproducts`,
      filtersPayload
    );
    
    if (response.data.message === "No products found") {
      console.log(response.data.message);
      dispatch(setNoProductsFound(true));
    } else {
      dispatch(setFilteredProducts(response.data.products || []));
      dispatch(setNoProductsFound(false));
    }

    dispatch(setLoading(false));

    // Navigate if on small screen
    if (isSmallScreen && typeof navigate === "function" && validFilters.length > 1) {
      setTimeout(() => {
        navigate("/shop");
        // console.log("Navigating to /shop after filter applied");
        dispatch(setFilterExpanded(false));
      }, 200);
    }
  } catch (error) {
    console.error("Error filtering products:", error);
    dispatch(setLoading(false));
  }
};
