import "./header.css";
import { useTheme } from "@emotion/react";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { selectScreenSize } from "../../store/reducers/userSlice";
import SearchInput from "./components/SearchInput";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import HeaderIcons from "./components/HeaderIcons";
import FilterExpanded from "../filter/FilterExpanded";
import { ReactComponent as Logo } from "../../media/logo/logo.svg";
import FormikWrapper from "../filter/FormikWrapper";
import { handleFilterSubmit } from "../../helpers/filterUtilsSubmit";
import { selectAllProducts } from "../../store/reducers/productSlice";
import { extractUniqueValues } from "../../helpers/filterUtils";
import {
  selectIsFilterExpanded,
  setFilterExpanded,
  setLoading,
} from "../../store/reducers/filterSlice";
import useGetTags from "../../helpers/hooks/useGetTags";
import useGetColors from "../../helpers/hooks/useGetColors";

function Header() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const showSmallSearch = isSmallScreen;
  const isExpanded = useSelector(selectIsFilterExpanded);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const products = useSelector(selectAllProducts);

  const tags = useGetTags();
  const colors = useGetColors();
  const submitFormRef = useRef(null);

  const sizes = extractUniqueValues(products, "size");
  const heelHeights = extractUniqueValues(products, "heelHeight");

  const initialValues = useMemo(
    () => ({
      filters: [],
    }),
    []
  );

  const expandFilters = () => {
    dispatch(setFilterExpanded(true));
  };

  const hideFilters = () => {
    dispatch(setFilterExpanded(false));
  };

  const closeAllModals = () => {
    setIsCartOpen(false);
    setIsMenuOpen(false);
    dispatch(setFilterExpanded(false));
  };

  const handleLogoClick = () => {
    closeAllModals();
    navigate("/");
  };

  const handleSubmit = (values) => {
    setLoading(true);
    handleFilterSubmit(
      { ...values, searchQuery },
      dispatch,
      heelHeights,
      sizes,
      tags.map((tag) => tag.name),
      colors.map((color) => color.name),
      isSmallScreen,
      navigate
    );
    setLoading(false);
  };

  const handleClickExpanded = () => {
    dispatch(setFilterExpanded(!isExpanded));
    if (!isExpanded) {
      navigate("/shop", { state: { filterExpanded: true } });
    }
  };

  // useEffect(() => {
  //   if (searchQuery === "") {
  //     handleSubmit({ filters: [] });
  //   }
  // }, [searchQuery]);

  return (
    <div className="heading-container">
      <div
        className="header-container"
        style={{
          backgroundColor: theme.palette.primary.main1,
          borderBottom: `1px solid ${theme.palette.customOppositeLighter.main}`,
        }}
      >
        <div className="header-logo-container">
          <Logo
            onClick={handleLogoClick}
            className="header-logo"
            style={{ width: isSmallScreen ? "100px" : "120px" }}
          />
        </div>
        <HeaderIcons
          isCartOpen={isCartOpen}
          setIsCartOpen={setIsCartOpen}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          isLanguageModalOpen={isLanguageModalOpen}
          setIsLanguageModalOpen={setIsLanguageModalOpen}
        />
      </div>

      {showSmallSearch && (
        <div
          className="mobile-search-container"
          style={{
            borderBottom: `1px solid ${theme.palette.customOppositeLighter.main}`,
            backgroundColor: theme.palette.primary.main1,
          }}
        >
          <div className="back-icon-container"></div>

          <div className="mobile-search">
            <SearchInput
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              toggleFilters={expandFilters}
              onSubmit={() => {
                handleSubmit({ filters: [], searchQuery });
              }}
            />
          </div>

          {!isExpanded ? (
            <div onClick={expandFilters} className="back-icon-container">
              <TuneIcon
                className="icon-hover"
                style={{
                  color: theme.palette.customOpposite.main,
                  fontSize: "1.2em",
                }}
              />
            </div>
          ) : (
            <div onClick={hideFilters} className="back-icon-container">
              <CloseIcon
                className="icon-hover"
                style={{
                  color: theme.palette.customOpposite.main,
                  fontSize: "1.2em",
                }}
              />
            </div>
          )}
        </div>
      )}

      {showSmallSearch && isExpanded && (
        <div className="small-screen-filter-container">
          <FormikWrapper
            formikRef={submitFormRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ submitForm }) => (
              <FilterExpanded
                sizes={sizes} // Pass sizes to FilterExpanded
                heelHeights={heelHeights} // Pass heel heights to FilterExpanded
                tags={tags.map((tag) => tag.name)} // Pass tag names to FilterExpanded
                colors={colors.map((color) => color.name)}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                setSubmitForm={submitForm}
                setIsExpanded={handleClickExpanded}
              />
            )}
          </FormikWrapper>
        </div>
      )}
    </div>
  );
}

export default Header;
